body {
  background-color: var(--bg-color);
  font-family: Verdana, sans-serif;
  line-height: 1.6;
}

main {
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding: var(--large-space);
  background-color: var(--text-bg-color);
  box-shadow: 0 40px 25px 6px var(--shadow-color);

  p, li {
    font-size: var(--font-size);
  }

  p {
    margin: var(--medium-large-space) 0;
  }
}

footer {
  color: var(--inverse-text-color);
  background-color: var(--inverse-bg-color);
  text-align: center;
  font-size: var(--font-size);
  padding: var(--large-space);
  margin-top: var(--large-space);
  font-weight: bold;
}

h1, h2, h3, h4 {
  color: var(--heading-color);
  line-height: normal;
}
h1 {
  font-size: var(--h1-font-size);
  line-height: 1;
  margin-top: 0;
  margin-bottom: var(--medium-large-space);
}
h2 {
  font-size: var(--h2-font-size);
  margin-top: var(--large-space);
  border-bottom: var(--divider-thickness) solid var(--divider-color);
}
h3 {
  font-size: var(--h3-font-size);
  margin-top: var(--large-space);
  border-bottom: var(--divider-thickness) dotted var(--divider-color);
}
h4 {
  font-size: var(--h4-font-size);
}
h5 { /* Item name */
  font-size: var(--font-size);
  margin: 0;
  padding-top: var(--small-space);
}
my-subheading {
  display: block;
  font-size: var(--subheading-font-size);
}

a {
  color: var(--link-color);
  text-decoration-thickness: 2px;
  text-decoration-color: var(--link-underline-color);
  text-underline-offset: 0.2rem;

  &:visited {
    color: var(--visited-link-color);
    text-decoration-color: var(--visited-link-underline-color);
  }
}

footer a {
  color: var(--alt-link-color);

  &:visited {
    color: var(--alt-visited-link-color);
  }
}

aside {
  border: var(--border-thickness) solid var(--border-color);
  background-color: var(--emph-bg-color);
  padding: var(--medium-space);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;

  p {
    margin: 0;

    &:last-child {
      margin-left: var(--medium-space);
    }
  }
}

.anchor {
  font-size: 70%;
  text-decoration: none;
  position: absolute;
  float: left;
  padding: 0 0.5rem;
  line-height: 1.7;
  opacity: 0;
  -webkit-user-select: none; /* Safari */
  user-select: none;
}
h2 .anchor {
  margin-left: -2.9rem;
}
h3 .anchor {
  margin-left: -2.8rem;
}
h4 .anchor {
  margin-left: -2.6rem;
}

.back-to-top {
  visibility: hidden;
	position: fixed;
	height: var(--large-space);
  line-height: var(--large-space);
	bottom: var(--medium-large-space);
	left: 50%;
  transform: translateX(-50%);
  padding: 0 var(--medium-space);
	background-color: var(--inverse-bg-color);
  border: var(--alt-border-thickness) solid var(--border-color);
	border-radius: var(--medium-large-space);
	text-align: center;
  justify-content: center;
  text-decoration: none;
	color: var(--inverse-text-color) !important;
  font-size: var(--large-font-size);
  font-weight: bold;
}

section.graphical-lists {
  ul {
    list-style-type: none;
    margin: 0 0 var(--large-space);
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    column-gap: var(--medium-large-space);
    row-gap: var(--large-space);
  }

  li {
    width: var(--figure-size);

    figure {
      margin: 0;
      line-height: normal;

      & > a {
        font-weight: bold;
      }

      figcaption {
        margin: var(--medium-space) 0;

        & p {
          margin: 0;
        }
      }
    }

    img {
      height: var(--figure-size);
      width: var(--figure-size);
      object-fit: contain;
      border: var(--alt-border-thickness) solid var(--alt-border-color);
      border-radius: var(--border-radius);
    }

    .missing-image {
      margin: 0;
      width: var(--figure-size);
      height: var(--figure-size);
      border: var(--alt-border-thickness) solid var(--alt-border-color);
      border-radius: var(--border-radius);
      text-align: center;
      font-size: var(--image-missing-icon-size);
      text-decoration: none;
      display: inline-block; /* So that `text-decoration: none` works. */
      line-height: var(--figure-size);
      -webkit-user-select: none; /* Safari */
      user-select: none;
    }
  }
}

@media (hover: hover) {
  a:hover {
    color: var(--hovered-link-color);
    text-decoration-thickness: 5px;
    text-decoration-color: var(--hovered-link-underline-color);
    /* transition: all 0.2s ease; */

    /* These transitions would be nice, but they're omitted due to this Firefox bug: */
    /* https://bugzilla.mozilla.org/show_bug.cgi?id=868975 */

    & img, & .missing-image {
      border-color: var(--hovered-link-underline-color) !important;
      border-width: var(--small-space) !important;
      /* transition: all 0.2s ease; */
    }
  }

  .back-to-top:hover {
    background-color: var(--inverse-bg-color-lighter);
  }

  footer a:hover {
    color: var(--visited-hovered-link-color);
    text-decoration-color: var(--visited-hovered-link-underline-color);
  }

  .anchor:hover, h2:hover .anchor, h3:hover .anchor, h4:hover .anchor {
    opacity: 1;
  }

  .top:hover, h2:hover .top, h3:hover .top, h4:hover .top {
    opacity: 1;
  }

  section.graphical-lists li a:hover .missing-image {
    font-size: var(--hovered-image-missing-icon-size);
    line-height: calc(var(--figure-size) - var(--small-space) * 2.5);
    /* 2.5 is a magic number to *usually* get rid of a tiny layout shift,
       but in any case there will typically not be any missing images. */
  }
}

@media (max-width: 1023px) {
  :root {
    --figure-size: var(--mobile-figure-size);
  }
  h1 {
    --h1-font-size: var(--mobile-h1-font-size);
  }
  h2 {
    --h2-font-size: var(--mobile-h2-font-size);
  }
  h3 {
    --h3-font-size: var(--mobile-h3-font-size);
  }
  h4 {
    --h4-font-size: var(--mobile-h4-font-size);
  }
  main {
    padding: var(--medium-large-space);
  }
  .anchor {
    display: none;
  }
}


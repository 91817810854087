:root {
  --bg-color: #f5d7d4;
  --text-color: black;
  --emph-text-color: #c43323;
  --shadow-color: #815c051a;
  --link-color: #2f2fc4;
  --link-underline-color: #7c7cea;
  --hovered-link-color: #b37626;
  --hovered-link-underline-color: #dfa962;
  --visited-link-color: #a22838;
  --visited-link-underline-color: salmon;
  --visited-hovered-link-color: #ffdf80;
  --visited-hovered-link-underline-color: #ffd194;
  --alt-link-color: #9f9fee;
  --alt-visited-link-color: #f79b90;
  --text-bg-color: #fffaf9;
  --emph-bg-color: #ffd8d4;
  --inverse-bg-color: #5d0c0c;
  --inverse-bg-color-lighter: #830e0e;
  --inverse-text-color: #fffaf9;
  --border-color: salmon;
  --alt-border-color: #770000;
  --divider-color: salmon;
  --heading-color: #770000;
  --font-size: 13pt;
  --large-font-size: 16pt;
  --h1-font-size: 48pt;
  --h2-font-size: 38pt;
  --h3-font-size: 34pt;
  --h4-font-size: 30pt;
  --subheading-font-size: 75%;
  --max-content-width: 70rem;
  --figure-size: 14.5rem;
  --image-missing-icon-size: 40pt;
  --hovered-image-missing-icon-size: 36pt;
  --divider-thickness: 0.3rem;
  --border-thickness: 0.2rem;
  --alt-border-thickness: 0.1rem;
  --border-radius: 0.4rem;
  --small-space: 0.5rem;
  --medium-space: 1rem;
  --medium-large-space: 1.5rem;
  --large-space: 3rem;
  --mobile-max-width: 1023px;
  --mobile-h1-font-size: 36pt;
  --mobile-h2-font-size: 32pt;
  --mobile-h3-font-size: 28pt;
  --mobile-h4-font-size: 24pt;
  --mobile-figure-size: 9rem;
}
